import { useRef, useEffect, useCallback } from "react";
export var useScrollToHash = function(path) {
    var refTimeout = useRef(null);
    var clearTimer = useCallback(function() {
        return clearTimeout(refTimeout.current);
    }, []);
    var moveToElement = useCallback(function(selector) {
        if (!selector || selector[0] === "#") {
            return;
        }
        var current = refTimeout.current;
        if (current) {
            clearTimer();
        }
        var el = selector && document.querySelector(selector);
        if (el) {
            refTimeout.current = setTimeout(function() {
                var _a;
                var header = document.querySelector("#main__header");
                var position = window.scrollY + el.getBoundingClientRect().top - ((_a = header === null || header === void 0 ? void 0 : header.clientHeight) !== null && _a !== void 0 ? _a : 0);
                window.scrollTo({
                    top: position,
                    behavior: "smooth"
                });
            }, 400);
        }
    }, [
        clearTimer
    ]);
    var handleHashChange = useCallback(function(event) {
        event.preventDefault();
        moveToElement(window.location.hash);
        return false;
    }, [
        moveToElement
    ]);
    useEffect(function() {
        var hash = path.includes("#") ? "#".concat(path.split("#").pop()) : null;
        moveToElement(hash);
        return function() {
            clearTimer();
        };
    }, [
        path,
        clearTimer,
        moveToElement
    ]);
    useEffect(function() {
        window.addEventListener("hashchange", handleHashChange, true);
        return function() {
            window.removeEventListener("hashchange", handleHashChange, true);
        };
    }, [
        handleHashChange,
        clearTimer,
        moveToElement
    ]);
};
